@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('./css/root.css');

/* ---------- Start - Default ---------- */
* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden !important;
}

input,
textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

header {
  top: 0;
  left: 0;
  width: 100%;
  height: 3.8rem;
  background-color: var(--global-main-color);
}

section .content-title {
  max-width: 95rem;
  height: auto;
  margin: auto;
  display: flex;
  padding: 0 5em;
  justify-content: space-between;
}

.title-sections {
  font-size: 44px;
  font-weight: 600;
  margin-top: -9px;
  color: var(--global-alternative-color)
}

.title-bar {
  width: 140px;
  height: 5px;
  margin-bottom: 0.8vh;
  background-color: var(--global-alternative-color);
}

.global-padding {
  padding: 0 5em;
}

@media (max-width: 1050px) {
  .global-padding {
    padding: 0 2.5em;
  }
}

@media (max-width: 900px) {
  section .content-title {
    padding: 0 2.5em;
  }

  .title-sections {
    font-size: 32px;
  }

  .title-bar {
    width: 120px;
    height: 3px;
  }
}

@media (max-width: 720px) {
  section .content-title {
    align-items: center;
    flex-direction: column;
  }

  .title-sections {
    font-size: 22px;
  }

  .title-bar {
    display: none;
  }
}

@media (max-width: 490px) {
  .global-padding {
    padding: 0 1.7em;
  }
}
/* ---------- End - Default ---------- */

/* ---------- Start - Header ---------- */
.content-header {
  max-width: 88rem;
  margin: auto;
  height: 100%;
  display: flex;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
}

.contact-with-whatsapp {
  display: flex;
  cursor: pointer;
  font-size: 1.05rem;
  font-weight: 400;
  align-items: center;
  color: var(--global-white-color)
}

.content-socials {
  height: 2rem;
  display: flex;
}

.icons-header {
  font-size: 2rem;
  margin-left: 0.5rem;
  color: var(--global-white-color)
}

@media (max-width: 1050px) {
  .content-header {
    padding: 0 3em;
  }
}

@media (max-width: 760px) {
  header {
    display: none;
  }
}
/* ---------- End - Header ---------- */

/* ---------- Start - Menu ---------- */
.menu {
  width: 100%;
  height: 6.2rem;
  background-color: var(--global-white-color)
}

.menu-icons {
  display: none;
}

.menu .content {
  height: 6.2rem;
  max-width: 88rem;
  margin: auto;
  padding: 0 1em;
  display: flex;
  align-items: center;
  position: relative;
}

.menu .sub-content {
  right: 0;
  display: flex;
  position: absolute;
  align-items: center;
}

.menu #logo {
  width: 320px;
  pointer-events: none;
}

.menu nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 1.1vh;
}

.menu nav li {
  padding: 1vh;
}

.menu nav a {
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: var(--global-main-color);
  transition: 0.7s ease;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
}

.menu nav a:hover {
  color: var(--global-white-color);
  background-color: var(--global-main-color);
}

@media (max-width: 1050px) {
  .menu {
    margin: auto;
    height: 100px;
    padding: 0 2.3vh 0 2.3vh;
  }

  .menu .content {
    max-width: 95%;
    height: 100px;
    display: flex;
    padding: 0;
  }

  .menu-icons {
    right: 0;
    cursor: pointer;
    display: flex;
    color: var(--global-white-color);
    padding: 1.6vh;
    font-size: 1.5rem;
    position: absolute;
    border-radius: 20px;
    background-color: var(--global-main-color)
  }

  .menu nav ul {
    display: block;
    padding-top: 0.8vh;
  }

  .menu nav li {
    padding: 0 0 20px 12px;
  }

  .menu .sub-content {
    opacity: 0;
    display: none;
    position: fixed;
    top: 160px;
    width: 300px;
    height: 100vh;
    background: #FFF;
    box-shadow: -10px 0px 16.4px -11px rgba(0, 0, 0, 0.10);
    padding: 10px 0 0 10px;
  }

  .menu .sub-content.active {
    z-index: 1 !important;
    opacity: 1;
    display: block;
    transition: all 1s ease;
  }

  .menu nav a::after {
    margin-bottom: -1vh;
  }

  .menu nav a:hover::after{
    width: 95.5%;
  }

  .menu-close {
    display: none;
  }
}

@media (max-width: 760px) {
  .menu .sub-content {
    top: 100px;
  }
}

@media (max-width: 510px) {
  .menu #logo {
    width: 260px;
  }
}
/* ---------- End - Menu ---------- */

/* ---------- Start - Main Photo ---------- */
.content-main-photo {
  width: 100vw;
  height: 120vh;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url('./images/main-header.png');
}

@media (max-width: 1270px) {
  .content-main-photo {
    background-position: 30%;
  }
}

@media (max-width: 900px) {
  .content-main-photo {
    height: 80vh;
    background-position: 14%;
  }
}
/* ---------- End - Main Photo ---------- */

/* ---------- Start - The Office ---------- */
section.content-the-office {
  width: 100%;
  height: 144vh;
  align-items: center;
  padding-top: 5vh;
}

section.content-the-office .data {
  max-width: 95rem;
  height: 134vh;
  margin: auto;
  display: flex;
  padding: 0 5em;
}

section.content-the-office .about-us {
  width: 55%;
  height: 100%;
}

section.content-the-office .image-about-us {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

section.content-the-office .about-us h1 {
  font-size: 79px;
  margin: -2.8vh 0 0 -0.5vh;
  color: var(--global-main-color);
}

section.content-the-office .about-us h2 {
  font-size: 36px;
  color: var(--global-alternative-color);
}

section.content-the-office .about-us h4 {
  font-size: 21px;
  font-weight: 600;
  text-align: justify;
  color: var(--global-main-color);
}

section.content-the-office .content-top-detail {
  width: 140px;
  height: 0.6vh;
  margin-bottom: 0.8vh;
  background-color: var(--global-alternative-color);
}

section.content-the-office .content-doctor {
  width: 95%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://www.eduardogabriel.com.br/db/images/about-us.jpg');
}

@media (max-width: 1510px) {
  section.content-the-office .about-us h4 {
    font-size: 20px;
  }
}

@media (max-width: 1350px) {
  section.content-the-office {
    height: 121vh;
  }

  section.content-the-office .data {
    height: 111vh;
  }

  section.content-the-office .about-us h1 {
    font-size: 69px;
  }

  section.content-the-office .about-us h2 {
    font-size: 32px;
  }

  section.content-the-office .about-us h4 {
    font-size: 16px;
    margin-top: -0.8vh;
  }
}

@media (max-width: 1200px) {
  section.content-the-office .about-us h1 {
    font-size: 55px;
    margin: -2.3vh 0 0 -0.5vh;
  }

  section.content-the-office .about-us h2 {
    font-size: 26px;
  }
}

@media (max-width: 1070px) {
  section.content-the-office {
    height: auto;
    margin-bottom: 5vh;
  }

  section.content-the-office .data {
    height: auto;
    display: flex;
    padding: 0 3em;
    flex-direction: column-reverse;
  }

  section.content-the-office .image-about-us,
  section.content-the-office .about-us {
    width: 100%;
  }

  section.content-the-office .content-doctor {
    width: 100%;
    height: 100vh;
  }

  section.content-the-office .about-us h1 {
    font-size: 69px;
  }

  section.content-the-office .about-us h2 {
    font-size: 32px;
  }

  section.content-the-office .about-us h4 {
    font-size: 18px;
  }
}

@media (max-width: 760px) {
  section.content-the-office .data {
    padding: 0 2.5em;
  }

  section.content-the-office .about-us h1 {
    font-size: 55px;
  }

  section.content-the-office .about-us h2 {
    font-size: 26px;
  }

  section.content-the-office .about-us h4 {
    font-size: 15px;
  }
}

@media (max-width: 560px) {
  section.content-the-office .content-doctor {
    height: 700px;
  }

  section.content-the-office .data {
    padding: 0 1.7em;
  }

  section.content-the-office .about-us h1 {
    font-size: 38px;
    margin: -1.2vh 0 0 -0.5vh;
  }

  section.content-the-office .about-us h2 {
    font-size: 18px;
    margin-top: 1vh;
  }

  section.content-the-office .about-us h4 {
    font-size: 13px;
  }
}
/* ---------- End - The Office ---------- */

/* ---------- Start - Areas of Activity ---------- */
section.content-areas-activity {
  width: 100%;
  height: auto;
  align-items: center;
  padding: 5vh 0 5vh 0;
  background-color: var(--global-alternative-background-color);
}

.data-areas-activity {
  max-width: 95rem;
  height: auto;
  margin: auto;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;
}

.areas {
  width: 40%;
}

.areas h1 {
  font-size: 20px;
  font-weight: 600;
  text-align: justify;
  margin-bottom: 5px;
}

.areas h4 {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: var(--global-white-color);
  background-color: var(--global-main-color);
  margin-top: 10px;
  border-radius: 8px;
  padding: 10px 0 10px 0;
  justify-content: center;
}

.area-icon {
  font-size: 20px;
}

.areas-img {
  width: 58%;
  height: 600px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url('https://eduardogabriel.com.br/db/images/area.png');
}

@media (max-width: 1180px) {
  .data-areas-activity {
    flex-direction: column-reverse;
  }

  .areas {
    width: 100%;
    margin-top: 10px;
  }

  .areas-img {
    width: 100%;
    height: 400px;
  }

  .areas h1 {
    font-size: 18px;
  }

  .areas h4 {
    font-size: 15px;
  }

  .area-icon {
    font-size: 14px;
  }
}

@media (max-width: 625px) {
  .areas-img {
    background-position: 80%;
  }

  .areas h1 {
    font-size: 15px;
  }

  .areas h4 {
    font-size: 13px;
    padding: 8px 0 8px 0;
  }

  .area-icon {
    font-size: 11px;
  }
}
/* ---------- End - Areas of Activity ---------- */

/* ---------- Start - Pro Bono Advocacy ---------- */
section.content-pro-bono-advocacy {
  width: 100%;
  height: auto;
  align-items: center;
  padding: 5vh 0 5vh 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://www.eduardogabriel.com.br/db/images/bg-pro-bono.png');
}

.data-pro-bono-advocacy {
  max-width: 95rem;
  height: auto;
  margin: auto;
  display: flex;
  margin-top: 12px;
  align-items: center;
  flex-direction: column;
}

.content-pro-bono-advocacy .title-sections {
  color: var(--global-white-color) !important;
}

.content-pro-bono-advocacy .title-bar {
  background-color: var(--global-white-color) !important;
}

.data-pro-bono-advocacy h1 {
  font-size: 28px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: uppercase;
  color: var(--global-white-color);
}

.data-pro-bono-advocacy h4 {
  font-size: 15px;
  font-weight: 400;
  border-radius: 8px;
  color: var(--global-white-color);
}

.data-pro-bono-advocacy h3 {
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 2em;
  transition: 0.5s ease-in-out;
  padding: 1em 4em 1em 4em;
  text-transform: uppercase;
  color: var(--global-white-color);
  border: 2px solid var(--global-alternative-color);
}

.data-pro-bono-advocacy h3:hover {
  cursor: pointer;
  background-color: var(--global-alternative-color);
}

@media (max-width: 720px) {
  .data-pro-bono-advocacy h1 {
    font-size: 14px;
    font-weight: 500;
  }

  .data-pro-bono-advocacy h4 {
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
  }

  .data-pro-bono-advocacy h3 {
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    margin-top: 2em;
    transition: 0.5s ease-in-out;
    padding: 0.8em 3em 0.8em 3em;
    text-transform: uppercase;
    color: var(--global-white-color);
    border: 2px solid var(--global-alternative-color);
  }
}
/* ---------- End - Pro Bono Advocacy ---------- */

/* ---------- Start - Oral Arguments ---------- */
section.content-oral-arguments {
  width: 100%;
  height: auto;
  align-items: center;
  padding: 5vh 0 5vh 0;
  background-color: var(--global-alternative-background-color);
}

.data-oral-arguments {
  max-width: 95rem;
  height: auto;
  margin: auto;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  justify-content: space-between;
}

.data-oral-arguments .argument {
  width: 32%;
  min-width: 360px;
  height: 545px;
  margin: 5px 0;
}

.argument iframe {
  width: 100%;
  height: 545px;
}

@media (max-width: 1270px) {
  .data-oral-arguments {
    flex-direction: column;
  }

  .data-oral-arguments .argument {
    width: 100%;
    min-width: 0;
  }
}
/* ---------- End - Oral Arguments ---------- */

/* ---------- Start - Contact ---------- */
section.content-contact {
  width: 100%;
  height: auto;
  padding: 5vh 0 5vh 0;
  background-color: var(--global-white-color);
}

.contacts {
  max-width: 95rem;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.contacts .socials {
  max-width: 50vw;
}

.contacts .socials .item {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  text-decoration: none;
  color: var(--global-main-color);
}

.contacts .map {
  width: 50%;
}

.contacts .custom-map {
  width: 100%;
  height: 500px;
}

@media (max-width: 1050px) {
  .contacts {
    flex-direction: column;
  }

  .contacts .socials {
    max-width: 100%;
  }

  .contacts .map {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 720px) {
  .contacts .socials .item {
    font-size: 16px;
  }

  .custom-icon {
    font-size: 1.9rem !important;
  }
}

@media (max-width: 490px) {
  .contacts .socials .item {
    font-size: 12px;
  }

  .custom-icon {
    font-size: 1.4rem !important;
    margin-right: 4px !important;
  }
}
/* ---------- End - Contact ---------- */

/* ---------- Start - Footer ---------- */
.content-footer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--global-main-color);
}

.content-footer h4 {
  font-size: 13px;
  font-weight: 300;
  padding: 1.6vh;
  text-align: center;
  color: var(--global-white-color);
}
/* ---------- End - Footer ---------- */